import React from 'react'
import Cookies from 'universal-cookie';
function LogOut() {
    const cookies = new Cookies();
  
    cookies.remove("user")
  
    console.log(cookies)
    window.location.href = "/login";

  return (
    <></>
  )
}

export default LogOut