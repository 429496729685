import React, { useState } from 'react'
import './Tools.css'
import NavBar from '../NavBar/NavBar'
import axios from 'axios'
import { kml } from "@tmcw/togeojson";

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
// // or less ideally


function Tools() {
  const [jkml, setJkml] = useState()
  const [jkml1, setJkml1] = useState([])
  const [mydataitem, setMydataitem] = useState([])
  const [mydataitem1, setMydataitem1] = useState([])
  const [mydataitem2, setMydataitem2] = useState([])

var arr =[];
  function getData() {
    axios.get('https://api.kasetr.com/getuser.php').then(res => {
      var data = res.data
      console.log(data)
    })
  }


  function button3() {

    const rows2 = mydataitem2?.map(item =>  JSON.stringify(item));
       
    const worksheet1 = XLSX.utils.json_to_sheet(rows2);
    console.log (worksheet1)
    const workbook = XLSX.utils.book_new();


    XLSX.utils.book_append_sheet(workbook, worksheet1, "Sheet1",);


    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    saveAs(blob, "test.xlsx");


  }
  function button2() {

    // console.log(jkml1)
    for (var i in jkml1) {
      // console.log(jkml1[i]["a"])
      var n1 = jkml1[i]["n"]
      var a1 = jkml1[i]["a"]
      // console.log(n1);
      // console.log(a1[0]) ;
      for (var i2 in a1[0]) {

        //   //   // console.log(a1)
        //  console.log(a1[0][i2][1]+","+a1[0][i2][0])

       
       
        arr.push({lat: a1[0][i2][1], lng: a1[0][i2][0]})
       


      }

      console.log(arr)
     
      mydataitem2.push({ [n1]: arr });
       arr=[]
    }
  }
  console.log(mydataitem2)







  function button1() {
      console.log(jkml)
    // console.log(jkml.features)

    for (var key in jkml.features) {
      const n = jkml.features[key].properties.name;
      const a = jkml.features[key].geometry.coordinates;

      mydataitem.push({ n, a })


    }
    setJkml1(mydataitem);
    console.log("แปลงแล้ว")
    // console.log(mydataitem)
    // console.log("ทำงานวันหยุดก็ไม่ได้ตัง"+mydataitem)

    // console.log(jkml.features[0].properties.name)
    // console.log(jkml.features[0].geometry.coordinates)
    // console.log(typeof(jkml.features[0].properties.name))
  }
  // console.log(jkml1)

  function getmkl() {
    setJkml([])
    setJkml1([])
    setMydataitem([])
    setMydataitem1([])
    setMydataitem2([])
    fetch("./data.kml")
      .then(function (response) {
        return response.text();
      })
      .then(function (xml) {
        setJkml(kml(new DOMParser().parseFromString(xml, "text/xml")));
        console.log("อ่านแล้ว")
      });
  }

  return (
    <div className='container-fluid'>

      <NavBar />
      <body>
        <p>Tools</p>

        <button type="button" class="btn btn-warning m-1" onClick={getmkl} >อ่าน</button>
        <button type="button" class="btn btn-warning m-1" onClick={button1} >แปลง</button>
        <button type="button" class="btn btn-warning m-1" onClick={button2} >B3</button>
        <button type="button" class="btn btn-warning m-1" onClick={button3} >B4</button>
        <button type="button" class="btn btn-warning m-1" onClick={getData} >getData</button>


      </body>
    </div>
  )
}

export default Tools