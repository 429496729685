import React, { useState, useRef, useContext, useCallback, useEffect } from "react";
import NavBar from '../NavBar/NavBar';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import axios from 'axios'
function ReportData() {

    function getData() {
        axios.get('https://api.kasetr.com/getMainData.php').then(res => {
            var data = res.data
            console.log(data)
            setMydata(data);
            setMydata1(data);
        })
    }

    useEffect(() => {
        usestap(); // this will fire only on first render


    }, []);



    async function usestap() {
        await getData();
      
      
    }



    const [mydata, setMydata] = useState();
 
    const [mydata1, setMydata1] = useState();
    const [mydataitem, setMydataitem] = useState([]);
    

    const [choost, setChoost] = useState("ทุกเขต")



  const c = []
    // const [rowss0, setRowss0] = useState();
    const [rowss0, setRowss0] = useState();
    const [rowss1, setRowss1] = useState();
    const [rowss2, setRowss2] = useState();

 
    function removeDuplicateStrings(inputArray) {
        return [...new Set(inputArray)]
    }

 
    
    function gget() {

        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        const formattedToday = dd + '/' + mm + '/' + yyyy;
        // console.log(mydata1)
        
      
        // console.log(typeof (choost))
        
        


       
        const worksheet1 = XLSX.utils.json_to_sheet(mydata1);
        const workbook = XLSX.utils.book_new();
    
   
        XLSX.utils.book_append_sheet(workbook, worksheet1, "Sheet1",);
    

        // Buffer to store the generated Excel file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

        saveAs(blob, formattedToday+"_"+choost + ".xlsx");



    }

    function run2() {
        const rows0 = mydata?.map(item => item.เขต);
        setRowss0(removeDuplicateStrings(rows0));
       
    }


    const optionchoost0 = (event) => {

        
        setMydata1([])
        setMydataitem([])
        if (event.target.value == 0) {
    
            setMydata1(mydata)
            setRowss1([])
            setRowss2([])
            setChoost("ทุกเขต")
        }
        else {

            for (var key in mydata) {

                if (event.target.value == mydata[key]["เขต"]) {



                    mydataitem.push(mydata[key])
                }
            }


            setMydata1(mydataitem)
            

          
            const rows1 = mydataitem?.map(item => item["ชื่อนสร"]);
          
            setRowss1(removeDuplicateStrings(rows1))

            setChoost("เขต " + event.target.value.toString());


        }


    };



    const optionchoost1 = (event) => {

        setMydata1([]);
        setMydataitem([]);

        if (event.target.value == 0) {
           
           

            setRowss2([])

        }
        else {

            setChoost(event.target.value)
            
            for (var key in mydata) {

                if (event.target.value === mydata[key]["ชื่อนสร"]) {

                    mydataitem.push(mydata[key])
                }
            }


            setMydata1(mydataitem)
            const rows2 = mydataitem?.map(item => item["ชื่อชาวไร่"]);
            setRowss2(removeDuplicateStrings(rows2))

        }


    };
    const optionchoost2 = (event) => {
        setChoost("ชาวไร่" + event.target.value.toString())

        setMydata1([])
        setMydataitem([])
        for (var key in mydata) {

            if (event.target.value == mydata[key]["ชื่อชาวไร่"]) {



                mydataitem.push(mydata[key])
            }
        }


        setMydata1(mydataitem)




    };



    return (
        <div className='container-fluid'>
            <NavBar />
            <body>

                <form >
                    <div class="row" >



                        <div class="col" >
                            <label for="zone" class="form-label">เลือกเขต:</label>
                            
                            <select class="form-select" onClick={ run2} onChange={optionchoost0}>

                                <option value="0" selected>รวมทุกเขต</option>
                                {rowss0?.map(d => (

                                    <option value={d}>เขต {d}</option>


                                ))}



                            </select>
                        </div>
                        <div class="col">
                            <div class="col">
                                <label for="zone" class="form-label">เลือก นสร.:</label>
                                <select class="form-select" onChange={optionchoost1}>
                                    <option value="0" selected>เลือก นสร</option>
                                    {rowss1?.map(d => (

                                        <option value={d}>{d}</option>


                                    ))}

                                </select>
                            </div>
                        </div>
                        <div class="col">
                            <div class="col">
                                <label for="zone" class="form-label">ที่ นสร ดูแลโควต้า:</label>
                                <select class="form-select" onChange={optionchoost2}>
                                    <option value="0" selected>เลือกชาวไร่</option>
                                    {rowss2?.map(d => (

                                        <option value={d}>{d}</option>


                                    ))}

                                </select>
                            </div>
                        </div>
                        <div class="col">
                            <div class="col">
                                <label for="zone" class="form-label">ส่งออก</label>
                                <div>
                                    <button type="button" class="btn btn-warning m-1" onClick={gget} >File Excel</button>
                                  
                                </div>


                            </div>
                        </div>
                    </div>
                </form>
                <p></p>



                <div className="table-responsive">
                <table className="table table-hover  w-auto mw-100">
                        <thead>
                            <tr>
                                <th scope="col">id</th>
                                <th scope="col">วันที่แจ้ง</th>
                                <th scope="col">เขต</th>
                                <th scope="col">โควต้า</th>
                                <th scope="col">ชื่อชาวไร่</th>
                                <th scope="col">พื้นที่จดแจ้ง</th>
                                <th scope="col">ชื่อนสร.</th>

                                <th scope="col">วันพร้อมปลูก</th>
                            </tr>
                        </thead>
                        <tbody>


                        {mydata1?.map((d, i) => (
                           
                                <tr>
                                    < td >{i+1}</td>
                                    < td >{d["tm1"]}</td>
                               
                                    < td >{d["เขต"]}</td>
                                    < td >{d["รหัสโควต้า"]}</td>
                                    < td >{d["ชื่อชาวไร่"]}</td>
                                    < td >{parseFloat(d["พื้นที่แจ้งจด"]).toFixed(2)}</td>
                                    < td >{d["ชื่อนสร"]}</td>
                                    < td >{d["วันพร้อมปลูก"]}</td>
                                    
                                </tr>

                            ))}






                        </tbody>
                    </table>
                </div>

            </body >
        </div >
    )
}

export default ReportData