
import React, { useState, useContext, createContext } from 'react'

import axios from 'axios'
import './Login.css'
import NavBar from '../NavBar/NavBar';
import Cookies from 'universal-cookie';
// export 

function Login() {
  
  const cookies = new Cookies(null, { path: '/' });



  const [d0, setD0] = useState();
  const [d1, setD1] = useState();
  const [d2, setD2] = useState();
  const [showpass, setShowpass] = useState(true);
  const [showpass1, setShowpass1] = useState("password");
  const [c1, setC1] = useState(true);
  const [c2, setC2] = useState(60);

 
  
  if (undefined == cookies.get("user")) {
    
    function login() {

      axios.get('https://www.kasetr.com/api/getUserWhere.php', {
        params: {
          isAdd: 'true',
          d1: d1,
          d2: d2,
  
        }
      }).then(res => {
        if (null !== res.data) {
          setD0(res.data);
          let expires = new Date();
          expires.setTime(expires.getTime() + (c2 * 60 * 1000));
          cookies.set("user",res.data,{ path: '/',  expires});
        
          
          // console.log(res.data[0]["ตำแหน่ง"]);
          if ("นักส่งเสริม" == res.data[0]["ตำแหน่ง"]) {
            // cookies.set("n1", res.data[0]["ตำแหน่ง"])
            window.location.href = "/Menu";
          }else{
            
            window.location.href = "/dashboard";
        }
  
  
  
        } else {
          alert("มีบางอย่างไม่ถูกต้อง")
          console.log("มีบางอย่างไม่ถูกต้อง")
        }
  
  
  
      })
  
  
  
  
  
  
  
    }
  
  
      return (
  
  
  
  
  
         <body className="text-center  d-flex justify-content-center">
  
            <main className="form-signin">
  
              <form>
                <img className="mb-4" src="./linux-logo.png" alt="" width="80" height="80" />
                <h1 className="h3 mb-3 fw-normal">เข้าใช้งาน ระบบ</h1>
  
                <div className="form-floating">
                  <input type="text" className="form-control" id="floatingInput" placeholder="ชื่อผู้ใช้งาน" value={d1} onChange={(e) => setD1(e.target.value)} />
                  <label for="floatingInput">ชื่อผู้ใช้งาน</label>
                </div><p></p>
                <div className="form-floating">
                  <input type={showpass1} className="form-control" id="floatingPassword" placeholder="รหัสผ่าน" value={d2} onChange={(e) => setD2(e.target.value)} />
                  <label for="floatingPassword">รหัสผ่าน</label>
                </div>
  
            
                <div className="checkbox mb-3">
                  <label>
                    <input type="checkbox" value={showpass} onChange={(e) => { setShowpass(!showpass); if(showpass == true){setShowpass1("text")}else( setShowpass1("password")); }} /> แสดงรหัส
                  </label>
                </div>

                <div className="checkbox mb-3">
                  <label>
                    <input type="checkbox" value={c1} onChange={(e) => { setC1(!c1); if(c1 == true){setC2(720)}else( setC2(60)); }} /> จดจำการเข้าใช้งาน (จะจดจำไว้ 30 วัน)
                  </label>
                </div>
                <a className="w-100 btn btn-lg btn-primary" type="button" onClick={login}>เข้าใช้งาน</a><p></p>
      
                <p className="mt-5 mb-3 text-muted">&copy; 2024</p>
              </form>
            </main>
  
          </body>
         
  
  
  
  
      )



} else {
  if ( "นักส่งเสริม" == cookies.get('user')[0]["ตำแหน่ง"]) {
    window.location.href = "/Menu";

  }else{
    window.location.href = "/dashboard";
  }

  }
}

export default Login
