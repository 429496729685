import React from 'react'
import NavBar from '../NavBar/NavBar';
import Cookies from 'universal-cookie';
function Menu() {
  const cookies = new Cookies();
    if (undefined == cookies.get("user")) {
    window.location.href = "/login";


} else {

  if ( "นักส่งเสริม" == cookies.get('user')[0]["ตำแหน่ง"]||"หัวหน้าเขต" == cookies.get('user')[0]["ตำแหน่ง"]) {
  
    return (
    
      <div className='container-fluid'>
        <NavBar />
<body>
      <p></p>
        <a type="button" href="Addjob" className="w-100 btn btn-outline-primary">จดแจ้ง</a>
        <p></p>
        <a type="button" href="MyJob"  className="w-100 btn btn-outline-primary">รายการจดแจ้ง</a>
       
  </body>

      </div>



  
  )
  
  }


}
}
export default Menu