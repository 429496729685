
import { GoogleMap, useJsApiLoader, Polygon, Marker } from '@react-google-maps/api';
import React, { useState, useRef, useContext, useCallback ,useEffect} from "react";

import NavBar from '../NavBar/NavBar';

import './PolyGon.css'
import * as geolib from 'geolib';
import { IoAddCircleOutline } from "react-icons/io5";






function PolyGon() {
    useEffect(() => {
        getLocation(); // จะทำงานครั้งแรก
  

    }, []);



    const [path, setPath] = useState([]);

    const [area, setArea] = useState(0);




   




    const containerStyle = {
        width: '50vw',
        height: '100vh'
    };




    function getarea(lg) {
        // const  area = geolib.getCenter(path);
        setArea(geolib.getAreaOfPolygon(lg) / 1600);

    };



    const { isLoaded } = useJsApiLoader({

        googleMapsApiKey: "AIzaSyCY3YGZiLsFRWa5JJ2FjoD-LY0Wxnf3Md8"
    });


    const [center, setCenter] = useState({ lat: 15.643080457818698, lng: 99.67119235357399 });

    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            console.log("Geolocation is not supported by this browser.")

        }
    };

    function showPosition(position) {
        console.log("Latitude:" + position.coords.latitude + "Longitude:" + position.coords.longitude)
        setCenter({ lat: position.coords.latitude, lng: position.coords.longitude })
        // setPosition({ lat: position.coords.latitude, lng: position.coords.longitude })



    };





    const onMapClick = (e) => {

        setPath([...path, { lat: e.latLng.lat(), lng: e.latLng.lng() }]);
        console.log("ทำงานวันหยุดก็ไม่ได้ตัง")
        console.log([...path, { lat: e.latLng.lat(), lng: e.latLng.lng() }])

        getarea([...path, { lat: e.latLng.lat(), lng: e.latLng.lng() }])



    };

    const onClickDelete = () => {
        setPath(path.slice(0, -1));
        console.log(typeof (path[0]));
    }


    // Define refs for Polygon instance and listeners
    const polygonRef = useRef(null);
    const listenersRef = useRef([]);

    // Call setPath with new edited path
    const onEdit = useCallback(() => {

        if (polygonRef.current) {
            const nextPath = polygonRef.current
                .getPath()
                .getArray()
                .map(latLng => {
                    return { lat: latLng.lat(), lng: latLng.lng() };
                    
                });

                console.log("ทำงานเอาเงินไม่ได้ทำงานเอาหน้า");
               console.log(nextPath);
               getarea(nextPath);
            setPath(nextPath);


        }
        
       
        
        // getarea([...path, { lat: latLng.lat(), lng: latLng.lng() }])
        // getarea([...path, { lat: e.latLng.lat(), lng: e.latLng.lng() }])

    }, [setPath]);





    // Bind refs to current Polygon and listeners
    const onLoad = useCallback(
        polygon => {

            polygonRef.current = polygon;
            const path = polygon.getPath();
            listenersRef.current.push(
                path.addListener("set_at", onEdit),
                path.addListener("insert_at", onEdit),
                path.addListener("remove_at", onEdit)
            );


        },
        [onEdit]
    );

    // Clean up refs
    const onUnmount = useCallback(() => {
        listenersRef.current.forEach(lis => lis.remove());
        polygonRef.current = null;
    }, []);

    // console.log("The path state is", path);


    // console.log("จะทำงานทุกครั้งที่มีการเปลี่ยงหรือใดๆก็ตาม")
    return isLoaded ? (
        <>
            <div className='setpage'>

                {/* <NavBar />
            <p>{center.lat},{center.lng}</p>
            <p><a href={"http://map.google.com/?q=" + center.lat + "," + center.lng}>{center.lat},{center.lng}</a></p> */}
                <button variant="primary" onClick={getLocation}>จุดที่ตั้ง</button>

                <GoogleMap
                    // mapContainerStyle={containerStyle}

                    mapContainerClassName="map-container"
                    zoom={18}
                    center={center}
                  
                    mapTypeId='satellite'
                    onClick={onMapClick}

                >

                    {/* <Marker position={position}

                        icon={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"} /> */}

                    <Polygon
                        // Make the Polygon editable / draggable
                        editable
                        
                        // draggable
                        path={path}
                        // Event used when manipulating and adding points
                        onMouseUp={onEdit}

                        // Event used when dragging the whole Polygon
                        onDragEnd={onEdit}
                        onLoad={onLoad}
                        onUnmount={onUnmount}

                    />

                </GoogleMap>
                {/* <IoAddCircleOutline className='nameicon' /> */}
                <button variant="primary" className='btnadd' onClick={onClickDelete}>ComeBack </button>
                <button variant="primary" className='btnadd' onClick={getarea}>Area </button>
                <p>{area.toFixed(2)}</p>
            </div>
        </>
    ) : <></>

}

export default PolyGon