
import React, { useState, useRef, useContext, useCallback, useEffect } from "react";
import { dataadmin } from '../../App'

import './AddUser.css'
import NavBar from '../NavBar/NavBar';
import axios from 'axios'

import Cookies from 'universal-cookie';
function AddUser() {
    const [d1, setD1] = useState();
    const [d2, setD2] = useState();
    const [d3, setD3] = useState();
    const [d4, setD4] = useState();
    const [d5, setD5] = useState();
    const [d6, setD6] = useState();
    const [d7, setD7] = useState("user");
    const [c1, setC1] = useState(false);
    const [c2, setC2] = useState(false);
    const [data, setData] = useState()
   
   
    useEffect(() => {
        getUser()


    });

    function getUser() {
        axios.get('https://www.kasetr.com/api/getUser.php').then(res => {
            setData(res.data);



        })
    }




    const cookies = new Cookies();




    if (undefined == cookies.get("user")) {
        window.location.href = "/login";
    
    
    } else {
    
      if ( "GIS" == cookies.get('user')[0]["ตำแหน่ง"]) {
      
      
   

   

    // https://api.kasetr.com/insertUser.php?isAdd=true&d1=110599&d2=password&d3=myname&d4=pirmpol&d5=1301&d6=all&d7=admin
    function insertUser(e) {

       
        e.preventDefault();
        if (data === null) {
            axios.get('https://www.kasetr.com/api/insertUser.php', {
                params: {
                    isAdd: 'true',
                    d1: d1,
                    d2: d2,
                    d3: d3,
                    d4: d4,
                    d5: d5,
                    d6: d6,
                    d7: d7,
                }
            }).then(function (response) {
                // console.log(response);
                window.alert("เรียบร้อย ผู้ใช้คนแรก " + d1 + " " + d3);
                setD1("");
                setD2("");
                setD3("");
                setD4("");
                setD5("");
                setD6("");

                setC1(false)
                setC2(false)
                getUser();

            })

            return;

        } else {

            for (var i in data) {

                if (d1 === data[i]["รหัสพนักงาน"]) {


                    window.alert("มีอยู่แล้ว :" + d1+" ชื่อพนักงาน : "+data[i]["ชื่อพนักงาน"] +" ตำแหน่ง : "+data[i]["ตำแหน่ง"]);
                    setD1("");
                    setD2("");
                    setD3("");
                    setD4("");
                    setD5("");
                    setD6("");

                    setC1(false)
                    setC2(false)
                    return;




                } else {

                    axios.get('https://www.kasetr.com/api/insertUser.php', {
                        params: {
                            isAdd: 'true',
                            d1: d1,
                            d2: d2,
                            d3: d3,
                            d4: d4,
                            d5: d5,
                            d6: d6,
                            d7: d7,
                        }
                    }).then(function (response) {
                        console.log(response);
                        window.alert("เรียบร้อย " + d1 + " " + d3);
                        setD1("");
                        setD2("");
                        setD3("");
                        setD4("");
                        setD5("");
                        setD6("");

                        setC1(false)
                        setC2(false)
                        getUser();

                    })

                    return;

                }
            }
        }








    }


   










    return (

        <body className="text-center  d-flex justify-content-center">

            <main className="form-signin">

                <form>

                    <h1 className="h3 mb-3 fw-normal">สร้างผู้ใช้งาน</h1>

                    <div className="form-floating">
                        <input type="text" className="form-control" id="d1" placeholder="รหัสพนักงาน" value={d1} onChange={(e) => { setD1(e.target.value); setD2(e.target.value); }} />
                        <label for="floatingInput">รหัสพนักงาน</label>
                    </div><p></p>
                    <div className="form-floating">
                        <input type="text" className="form-control" id="d2" placeholder="รหัสผ่าน" value={d2} onChange={(e) => setD2(e.target.value)} />
                        <label for="floatingInput">รหัสผ่าน</label>
                    </div><p></p>
                    <div className="form-floating">
                        <input type="text" className="form-control" id="d3" value={d3} placeholder="ชื่อพนักงาน กรุณาใส่คำนำหน้า" onChange={(e) => setD3(e.target.value)} />
                        <label for="floatingInput">ชื่อพนักงาน กรุณาใส่คำนำหน้า</label>
                    </div><p></p>

                    <div className="mb-3">

                        <select className="form-select" id="d4" value={d4} onChange={(e) => { if ("นักส่งเสริม" == e.target.value) { setC1(true); setC2(true); setD4(e.target.value); } else if ("หัวหน้าเขต" == e.target.value) { setC1(true); setC2(true); setD4(e.target.value); } else { setC1(false); setC2(false); setD4(e.target.value); setD5("ทั้งหมด"); setD6("ไม่มี"); } }} >
                            <option value="ไม่ได้เลือก" selected>เลือก..ตำแหน่ง.</option>
                            <option value="นักส่งเสริม">นักส่งเสริม</option>
                            <option value="หัวหน้าเขต">หัวหน้าเขต</option>
                            <option value="หัวหน้าแผนก">หัวหน้าแผนก</option>
                            <option value="ธุรการเขต">ธุรการ เขต</option>
                            <option value="ธุรการทั่วไป">ธุรการทั่วไป</option>
                            <option value="นักพัฒนาเพิ่มผลผลิต">นักพัฒนาเพิ่มผลผลิต</option>
                            <option value="GIS">GIS</option>
                            <option value="ผู้บริหาร">ผู้บริหาร</option>
                        </select>
                    </div>

                    {c1 ? (<div className="mb-3">

                        <select className="form-select" id="d5" value={d5} onChange={(e) => setD5(e.target.value)}>
                            <option value="ไม่ได้เลือก" selected >เลือก..เขต</option>
                            <option value="1">เขต 1</option>
                            <option value="2">เขต 2</option>
                            <option value="3">เขต 3</option>
                            <option value="4">เขต 4</option>
                            <option value="5">เขต 5</option>
                            <option value="6">เขต 6</option>
                            <option value="7">เขต 7</option>
                            <option value="8">เขต 8</option>
                            <option value="9">เขต 9</option>


                        </select>


                    </div>) : (<></>)}

                    {c2 ? (<div className="form-floating" >
                        <input type="text" className="form-control" id="d6" value={d6} placeholder="รหัส นสร.ประจำเขต" onChange={(e) => setD6(e.target.value)} />
                        <label for="floatingInput">รหัส นสร.ประจำเขต</label><p></p>
                    </div>) : (<></>)}





                    <a className="w-100 btn btn-lg btn-primary" type="button" onClick={insertUser}>สร้างผู้ใช้งาน</a><p></p>
                    <a className="w-100 btn btn-lg btn-primary" type="button" href="user">ย้อนกลับ</a><p></p>


                </form>
            </main>

        </body>



    )
}else{
    window.location.href = "/404";
}
    }
}

export default AddUser