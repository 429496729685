
import './NavBar.css'

import React, { useState, useEffect } from 'react'
import Cookies from 'universal-cookie';

function NavBar() {

  const [click, setCilck] = useState(false);
  const hacdClick = () => setCilck(!click);
  const closemenu = () => (setCilck(false));

  const cookies = new Cookies();

  if (undefined == cookies.get("user")) {
    return (
      <header>
        <nav className="navbar navbar-expand-md fixed-top navbar-dark bg-primary " aria-label="Fourth navbar example">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">DoKaSetr</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarsExample04">
              <ul className="navbar-nav me-auto mb-2 mb-md-0 ">


                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="home">หน้าแรก</a>
                </li>



                <li className="nav-item">
                  <a className="nav-link  disabled" href="Toost">เช่าเครื่องมือ</a>
                </li>
                <li className="nav-item">

                  <a className="nav-link" href="Login">เข้าใช้งาน</a>

                </li>

              </ul>

            </div>
          </div>
        </nav>

      </header>
    )


  } else {

    if ("นักส่งเสริม" == cookies.get('user')[0]["ตำแหน่ง"]) {
      return (
        <header>
          <nav className="navbar navbar-expand-md fixed-top navbar-dark bg-primary " aria-label="Fourth navbar example">
            <div className="container-fluid">
              <a className="navbar-brand" href="/">DoKaSetr</a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>

              <div class="collapse navbar-collapse" id="navbarsExample04">
                <ul className="navbar-nav me-auto mb-2 mb-md-0 ">




                  <li className="nav-item">
                    <a className="nav-link  disabled" href="Toost">เช่าเครื่องมือ</a>
                  </li>
                  <li className="nav-item">

                    <a className="nav-link" href="menu">เมนู</a>

                  </li>
                  <li className="nav-item">

                    <a className="nav-link" href="Myjob">รายการจดแจ้ง</a>

                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="LogOut" >ออกจากระบบ</a>
                  </li>
                </ul>

              </div>
            </div>
          </nav>

        </header>
      )
    } else if ("หัวหน้าเขต" == cookies.get('user')[0]["ตำแหน่ง"]) {
      return (
        <header>
          <nav className="navbar navbar-expand-md fixed-top navbar-dark bg-primary " aria-label="Fourth navbar example">
            <div className="container-fluid">
              <a className="navbar-brand" href="/">DoKaSetr</a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>

              <div class="collapse navbar-collapse" id="navbarsExample04">
                <ul className="navbar-nav me-auto mb-2 mb-md-0 ">




                  <li className="nav-item">
                    <a className="nav-link " href="Dashboard">กระดาษสรุป</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="Report">รายงาน</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="Maps">แผนที่</a>
                  </li>
                  <li className="nav-item">

                    <a className="nav-link" href="menu">เมนู</a>

                  </li>
                  <li className="nav-item">

                    <a className="nav-link" href="Myjob">รายการจดแจ้ง</a>

                  </li>



                  <li className="nav-item">
                    <a className="nav-link  disabled" href="Toost">เช่าเครื่องมือ</a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="LogOut" >ออกจากระบบ</a>
                  </li>
                </ul>

              </div>
            </div>
          </nav>

        </header>
      )
    } else {
      return (
        <header>
          <nav className="navbar navbar-expand-md fixed-top navbar-dark bg-primary " aria-label="Fourth navbar example">
            <div className="container-fluid">
              <a className="navbar-brand" href="/">DoKaSetr</a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>

              <div class="collapse navbar-collapse" id="navbarsExample04">
                <ul className="navbar-nav me-auto mb-2 mb-md-0 ">




                  <li className="nav-item">
                    <a className="nav-link " href="Dashboard">กระดาษสรุป</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="Report">รายงาน</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="Maps">แผนที่</a>
                  </li>
               
                  { ("GIS" == cookies.get('user')[0]["ตำแหน่ง"])?(  <li className="nav-item">
                    <a className="nav-link" href="user">ผู้ใช้งาน</a>
                  </li>):(<></>)

                 }



                  <li className="nav-item">
                    <a className="nav-link  disabled" href="Toost">เช่าเครื่องมือ</a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="LogOut" >ออกจากระบบ</a>
                  </li>
                </ul>

              </div>
            </div>
          </nav>

        </header>
      )
    }



  }
}



export default NavBar