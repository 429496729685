
import React, { useState, useRef, useContext, useCallback, useEffect } from "react";
import { kml } from "@tmcw/togeojson";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import tokml from 'tokml';
function Geo() {

    const [filekml, setKml] = useState(null)
    const [datakml, setDatakml] = useState(null)
    const [dataexcel, setDataexcel] = useState(null);
    const [nsr, setNsr] = useState(null);
    const [nsr1, setNsr1] = useState(null);
    const [jkml, setJkml] = useState()
    const [jcsv, setJcsv] = useState()
    const [jkml1, setJkml1] = useState([])
    const [mydataitem, setMydataitem] = useState([])
    const [mydataitem1, setMydataitem1] = useState([])
    const [mydataitem2, setMydataitem2] = useState([])
    var arr = [];
    var arr1 = [];
    const [tokmld, setTokmld] = useState([])

    const onImageChange = (event) => {
        // console.log(event.target.files[0])
        if (event.target.files && event.target.files[0]) {
          
            fetch(URL.createObjectURL(event.target.files[0]))
            .then(function (response) {
                return response.text();
            })
            .then(function (xml) {

                setDatakml(kml(new DOMParser().parseFromString(xml, "text/xml")));
                console.log(kml(new DOMParser().parseFromString(xml, "text/xml")))
            });
        }
    }

    const handleFileUpload = (e) => {
        if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        const reader = new FileReader();
    
        reader.onload = (event) => {
          const workbook = XLSX.read(event.target.result, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const sheetData = XLSX.utils.sheet_to_json(sheet);
    
          setDataexcel(sheetData);
          const i = sheetData.map((d, k) => d["นสร."])
          setNsr(removeDuplicateStrings(i));
          console.log(removeDuplicateStrings(sheetData.map(d => d["นสร."])));
          console.log(sheetData)
        };
    
        reader.readAsBinaryString(file);
    }
      };


      function removeDuplicateStrings(inputArray) {
        return [...new Set(inputArray)]
      }

    function getmkl() {
        console.log("กำลังอ่านไฟล์")

      
        console.log("อ่านแล้ว")
    }


    function button1() {
        console.log("ทำงาน")
    
        for (var key in dataexcel) {
    
          if (nsr1 == dataexcel[key]["นสร."]) {
    
            for (var key1 in datakml["features"]) {
    
    
              if (datakml["features"][key1]["id"] == dataexcel[key]["รหัสแปลง"]) {
    
                // console.log(jcsv[key]["ลำดับ"]);
                // console.log(jcsv[key]["นสร."]);
                // console.log( jkml["features"][key1]["type"])
                // console.log( jkml["features"][key1]["geometry"])
                // console.log( jkml["features"][key1]["properties"]["description"]["value"])
                // console.log( jkml["features"][key1]["id"])
    
    
                arr.push({
                  type: datakml["features"][key1]["type"], geometry: datakml["features"][key1]["geometry"],
                 
                  
    
                  properties: {
                    name: dataexcel[key]["ลำดับ"] + " --> " + dataexcel[key]["รหัสแปลง"],
                    "description": datakml["features"][key1]["properties"]["description"]["value"],
                    "stroke": "#555555",
                    "stroke-width": 2,
                    "stroke-opacity": 1,
                    "fill": "#00aa22",
                    "fill-opacity": 0.5
                  
                  }
                })
              }
            }
          }
    
        }
    
        setTokmld(arr)
        console.log(arr)
        console.log("หยุดทำงาน")
      }
    
      // console.log(tokml)
    
    
    
    
      function gettokml() {
    
        const geoJsonData = {
          "type": "FeatureCollection",
          "features": tokmld
        };
    
        const kmlData = tokml(geoJsonData);
        // console.log(geoJsonData);
        console.log(kmlData);
        exportFile(kmlData)
    
      }
    
    
      function exportFile(kmlData) {
        
      
        var blob = new Blob([kmlData], { type: "text/plain;charset=utf-8" });
        saveAs(blob, nsr1+".kml");
      }
    



    return (
        <body>

            <div><h5>File Kml</h5>
                <input type="file" onChange={onImageChange} className="filetype" />
                <h5>File Excel</h5>
                <input type="file" onChange={handleFileUpload} className="filetype" />
                {/* <img alt="preview image" width={400} src={image}/> */}
            </div>
            {/* <button type="button" class="btn btn-warning m-1" onClick={readexcel} >Read Excel</button> */}
            <form >
          <div class="row" >



            <div class="col" >
              <label for="zone" class="form-label">เลือก:</label>

              <select class="form-select" onChange={(event) => { setNsr1(event.target.value) }}>

                <option value="0" selected>เลือก พื้นที่ นสร.</option>
                {nsr?.map((d) => (
                    <option value={d}>{d}</option>
                ))}
              </select>
            </div>
          </div>
        </form>




                <button type="button" class="btn btn-warning m-1" onClick={button1} >จัดเรียงข้อมูล</button>
<button type="button" class="btn btn-warning m-1" onClick={gettokml} >สร้างไฟล์ kml</button>

        </body>
    )
}

export default Geo