import React, { useState, useRef, useContext, useCallback, useEffect } from "react";
import axios from 'axios'
import NavBar from '../NavBar/NavBar';

import Cookies from 'universal-cookie';

function User() {
    const [user, setUser] = useState()


    useEffect(() => {
        getUser()
      

    }, []);


    function getUser() {
        axios.get('https://www.kasetr.com/api/getUser.php').then(res => {
            var data = res.data
            setUser(data)
       
            // console.log(data)
        }).then(function () {

          
        })
    }
 
    const cookies = new Cookies();




    if (undefined == cookies.get("user")) {
        window.location.href = "/login";
    
    
    } else {
    
      if ( "GIS" == cookies.get('user')[0]["ตำแหน่ง"]) {
      
      
   
    return (
        <div className='container-fluid'>
        <NavBar />
        <body>

            <form >
                <div className="row" >


                
                    <div className="col">
                        <div className="col">
                            <label for="zone" className="form-label">สร้างผู้ใช้งาน</label>
                            <div>
                                <a type="button" className="btn btn-warning m-1" href="AddUser" >สร้างผู้ใช้งาน</a>
                              
                            </div>


                        </div>
                    </div>
                </div>
            </form>
            <p></p>


        
        <div className="table-responsive">




            <table className="table table-hover ">
                <thead>
                    <tr>
                        <th scope="col">ลำดับ</th>
                        <th scope="col">รหัสพนักงาน</th>
                        <th scope="col">ชื่อพนักงาน</th>
                        <th scope="col">ตำแหน่ง</th>
                        <th scope="col">เขต</th>
                        <th scope="col">รหัสในเขต</th>

                    </tr>
                </thead>
                <tbody>


                  
                    {user?.map((d, i) => (

                        <tr>

                            < td >{i+1}</td>
                            < td >{d["รหัสพนักงาน"]}</td>
                            < td >{d["ชื่อพนักงาน"]}</td>
                            < td >{d["ตำแหน่ง"]}</td>
                            < td >{d["รหัสเขต"]}</td>
                            < td >{d["เขตรับผิดชอบ"]}</td>


                        </tr>

                    ))}
                   






                </tbody>
            </table>
        </div>
        </body>
        </div>
    )}else{
        window.location.href = "/404";
    }
        }
}

export default User