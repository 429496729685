import React, { useState, useEffect } from "react";
import axios from 'axios'
import Cookies from 'universal-cookie';
import './MyJob.css'
function MyJob() {
  
    useEffect(() => {
        getData();




    }, []);

    const cookies = new Cookies();
    
    const [mydata1, setMydata1] = useState();
    const [todayareasum, setTodayareasum] = useState();
    const [todaypang, setTodaypang] = useState();

    // console.log(typeof(cookies.get('user')[0]["เขตรับผิดชอบ"]))
    // console.log(cookies.get('user')[0]["เขตรับผิดชอบ"])
    // console.log(cookies)
   
    const todatarr = []
    function getData() {
        axios.get('https://api.kasetr.com/getMainData.php').then(res => {
            var data = res.data

            // console.log(data)
          
            let todayarea = 0;
            let todaycon = 0;
         

            for (var ii in data) {


              if( data[ii]["รหัสนสร"] == cookies.get('user')[0]["เขตรับผิดชอบ"]){
              
                
                    todatarr.push(data[ii])
                  
                    todayarea = todayarea + parseFloat(data[ii]["พื้นที่แจ้งจด"])
                    todaycon = todaycon + 1
              }
                

            }
            setTodaypang(todaycon);
            setTodayareasum(todayarea.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
            
            setMydata1(todatarr)





        })
    }







    return (



        <div className='container-fluid'>
         
            
            <a type="button" class="w-100 btn btn-primary" href="Menu" >ย้อนกลับ </a>
<p></p>
               
<p><h5>จำนวนแปลงที่แจ้ง {todaypang} แปลง พื้นที่รวม {todayareasum} ไร่</h5></p>
<div className="table-responsive">
                <table className="table table-hover  w-auto mw-100">
                    <thead>
                        <tr>
                            <th scope="col">id</th>
                            <th scope="col">วันที่แจ้ง</th>
                           
                            <th scope="col">โควต้า</th>
                            <th scope="col" className="col-4">ชื่อชาวไร่</th>
                            <th scope="col">พื้นที่จดแจ้ง</th>
                       
                            <th scope="col">วันพร้อมปลูก</th>
                        </tr>
                    </thead>
                    <tbody>


                        {mydata1?.map((d, i) => (

                            <tr>
                                < td >{i + 1}</td>
                                < td >{d["tm1"]}</td>

                           
                                < td >{d["รหัสโควต้า"]}</td>
                                < td >{d["ชื่อชาวไร่"]}</td>
                                < td >{parseFloat(d["พื้นที่แจ้งจด"]).toFixed(2)}</td>
                               
                                < td >{d["วันพร้อมปลูก"]}</td>

                            </tr>

                        ))}






                    </tbody>
                </table>

                </div>
        </div>
  )
}

export default MyJob