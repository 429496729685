
import { GoogleMap, useJsApiLoader, Polygon, Marker } from '@react-google-maps/api';
import React, { useState, useRef, useContext, useCallback, useEffect } from "react";
import NavBar from '../NavBar/NavBar';
import tokml from 'tokml';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import "./Maps.css"
import axios from 'axios'
import { PiPolygon } from 'react-icons/pi';




function Maps() {

    function getData() {
        axios.get('https://api.kasetr.com/getMainData.php').then(res => {
            var data = res.data
            // console.log(data)
            setMydata(data);
            setMydata1(data);
            // return data;

        }).then((data) => {
            // setPolygons(data?.map(d => (JSON.parse(d["Polygon"]))))
            // console.log(data?.map(d => (JSON.parse(d["Polygon"]))))
        })
    }

    useEffect(() => {
        usestap(); // this will fire only on first render


    }, []);



    async function usestap() {
        await getData();


    }



    const [mydata, setMydata] = useState();

    const [mydata1, setMydata1] = useState();
    const [mydataitem, setMydataitem] = useState([]);


    const [choost, setChoost] = useState("ทุกเขต")



    const c = []

    const [rowss0, setRowss0] = useState();
    const [rowss1, setRowss1] = useState();
    const [rowss2, setRowss2] = useState();


    function removeDuplicateStrings(inputArray) {
        return [...new Set(inputArray)]
    }


    function exportFile() {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        const formattedToday = dd + '/' + mm + '/' + yyyy;
        const arr = []
        const arr1 = []
        console.log(mydata1)



        for (var d in mydata1) {

            const datalatlng = JSON.parse(mydata1[d]["Polygon"]);

            console.log(mydata1[d]["id"])

            // console.log([datalatlng[0].lng,datalatlng[0].lat,0])

            const polygon1 = [datalatlng?.map(w => ([w.lng, w.lat, 0]))]

            polygon1[0].push([datalatlng[0].lng, datalatlng[0].lat, 0])








            // console.log(JSON.parse(mydata1[d]["Polygon"]));

            arr.push({
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [polygon1]

                },



                "properties": {
                    "name": mydata1[d]["id"] + " - " + mydata1[d]["รหัสโควต้า"] + " - " + mydata1[d]["ชื่อชาวไร่"],
                    "description": "<font color='green' size='+1'>ข้อมูลพื้นที่จดแจ้ง</font><br/><font color='blue' size='+1'>" + mydata1[d]["รหัสโควต้า"] + " - " + mydata1[d]["ชื่อชาวไร่"] + "</font><br/>หมู่บ้าน " + mydata1[d]["หมู่บ้าน"] + "<br/>" + mydata1[d]["ตำบล"] + "<br/>" + mydata1[d]["อำเภอ"] + "<br/>" + mydata1[d]["จังหวัด"] + "<br/><hr/><table><tr></tr><tr><td align='left'>พื้นที่</td><td align='left'><font color='blue'>" + parseFloat(mydata1[d]["พื้นที่แจ้งจด"]).toFixed(2) + "</font></td></tr><tr></tr><tr><td align='left'>พืชปัจจุบัน</td><td align='left'><font color='blue'>" + mydata1[d]["พืชปัจจุบัน"] + "</font></td> </tr>    <td align='left'>วันที่เก็บเกี่ยวพืช</td><td align='left'><font color='blue'>" + mydata1[d]["วันเก็บเกี่ยว"] + "</font></td>    <tr>    <td align='left'>วันพร้อมปลูก</td><td align='left'><font color='blue'>" + mydata1[d]["วันพร้อมปลูก"] + "</font></td></tr>  \n\t\t\t\t\t</table><hr/><font color='0000ff' size='+1'>เขต - " + mydata1[d]["เขต"] + "</font><br/><font size='+1'>" + mydata1[d]["รหัสนสร"] + " - " + mydata1[d]["ชื่อนสร"] + "</font>   <hr/><table><tr></table> <font color='blue' size='-1'>Update on " + formattedToday + "</font>",
                    "tessellate": true,
           
            "style": {
                "__comment": "all SVG styles allowed",
                "fill":"red",
                "stroke-width":"3",
                "fill-opacity":0.6

            }

                },
                "id": mydata1[d]["id"]



            })

        }


        const geoJsonData = {
            "type": "FeatureCollection",
            "features": arr



        };

        const kmlData = tokml(geoJsonData);
        console.log(geoJsonData);
        console.log(kmlData);

        var blob = new Blob([kmlData], { type: "text/plain;charset=utf-8" });
        saveAs(blob, formattedToday + "-" + choost + ".kml");
    }


    function run2() {
        const rows0 = mydata?.map(item => item.เขต);
        setRowss0(removeDuplicateStrings(rows0));


    }
    function run3(data) {

        const p = data?.map((d, i) => (JSON.parse(d["Polygon"])))

        return (
            <Polygon>
                paths{p}

            </Polygon>
        )
    }

    const optionchoost0 = (event) => {


        setMydata1([])
        setMydataitem([])
        if (event.target.value == 0) {

            setMydata1(mydata)

            setRowss1([])
            setRowss2([])
            setChoost("ทุกเขต")
        }
        else {

            for (var key in mydata) {

                if (event.target.value == mydata[key]["เขต"]) {

                    // console.log(mydata[key])
                    mydataitem.push(mydata[key])

                }
            }




            setMydata1(mydataitem)


            const rows1 = mydataitem?.map(item => item["ชื่อนสร"]);

            setRowss1(removeDuplicateStrings(rows1))

            setChoost("เขต " + event.target.value.toString());


        }


    };



    const optionchoost1 = (event) => {

        setMydata1([]);
        setMydataitem([]);

        if (event.target.value == 0) {



            setRowss2([])

        }
        else {

            setChoost(event.target.value)

            for (var key in mydata) {

                if (event.target.value === mydata[key]["ชื่อนสร"]) {

                    mydataitem.push(mydata[key])
                }
            }




            setMydata1(mydataitem);
            const rows2 = mydataitem?.map(item => item["ชื่อชาวไร่"]);
            setRowss2(removeDuplicateStrings(rows2))

        }


    };
    const optionchoost2 = (event) => {
        setChoost("ชาวไร่" + event.target.value.toString())

        setMydata1([])
        setMydataitem([])
        for (var key in mydata) {

            if (event.target.value == mydata[key]["ชื่อชาวไร่"]) {



                mydataitem.push(mydata[key])
            }
        }


        setMydata1(mydataitem)


    };




    const { isLoaded } = useJsApiLoader({

        googleMapsApiKey: "AIzaSyCY3YGZiLsFRWa5JJ2FjoD-LY0Wxnf3Md8"
    });


    const [center, setCenter] = useState({ lat: 15.643080457818698, lng: 99.67119235357399 });

    const containerStyle = {
        width: '99vw',
        height: '75vh'
    };

    return isLoaded ? (
        <>

            <body>
                <NavBar />

                <form >
                    <div className="row" >



                        <div className="col">
                            <label for="zone" className="form-label">เลือกเขต:</label>
                            <select className="form-select" onClick={run2} onChange={optionchoost0}>

                                <option value="0" selected>รวมทุกเขต</option>
                                {rowss0?.map(d => (

                                    <option value={d}>เขต {d}</option>


                                ))}



                            </select>
                        </div>
                        <div className="col">
                            <div className="col">
                                <label for="zone" className="form-label">เลือก นสร.:</label>
                                <select className="form-select" onChange={optionchoost1}>
                                    <option value="0" selected>เลือก นสร</option>
                                    {rowss1?.map(d => (

                                        <option value={d}>{d}</option>


                                    ))}

                                </select>
                            </div>
                        </div>
                        <div className="col">
                            <div className="col">
                                <label for="zone" className="form-label">ที่ นสร ดูแลโควต้า:</label>
                                <select className="form-select" onChange={optionchoost2}>
                                    <option value="0" selected>เลือกชาวไร่</option>
                                    {rowss2?.map(d => (

                                        <option value={d}>{d}</option>


                                    ))}

                                </select>
                            </div>
                        </div>
                        <div className="col">
                            <div className="col">
                                <label for="zone" className="form-label">ส่งออก</label>
                                <div>
                                    <button type="button" className="btn btn-warning m-1" onClick={exportFile} >File KML</button>

                                </div>


                            </div>
                        </div>
                    </div>
                </form>

                <p></p>
                <div className='setpage'>





                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        mapTypeId='satellite'
                        // mapContainerClassName="map-container"
                        zoom={10}
                        center={center}

                    // onClick={onMapClick}



                    >





                        {mydata1?.map((d, i) => (



                            <Polygon



                                path={JSON.parse(d["Polygon"])}


                                options={{ fillColor: '#0bb3a2', fillOpacity: 0.2, strokeColor: "#59f08e", strokeOpacity: 0.8 }}
                                onClick={() => { alert("รหัสโควต้า " + d["รหัสโควต้า"] + " ชื่อ" + d["ชื่อชาวไร่"] + "\n พืชปัจจุบัน : " + d["พืชปัจจุบัน"] + "\n วันเก็บเกี่ยว : " + d["วันเก็บเกี่ยว"] + "\n วันพร้อมปลูก : " + d["วันพร้อมปลูก"] + "\n พื้นที่จดแจ้ง : " +parseFloat(d["พื้นที่แจ้งจด"]).toFixed(2)+ "\n นสร. : " + d["ชื่อนสร"] + "\n เขต : " + d["เขต"]); }}




                            />
                        ))}


                        <run3 mydata1 />





                    </GoogleMap>

                </div>
            </body>
        </>
    ) : <></>
}

export default Maps