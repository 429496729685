import React, { useState, useEffect } from "react";
import './DashBoard.css'
import NavBar from '../NavBar/NavBar'
import { MdToday } from "react-icons/md";
import { PiMapPinSimpleAreaBold } from "react-icons/pi";
import { GiFarmTractor } from "react-icons/gi";
import { GiSugarCane } from "react-icons/gi";
import axios from 'axios'

import Cookies from 'universal-cookie';
function DashBoard() {

    useEffect(() => {
        getData();




    }, []);

    // const cookies = new Cookies();
    const [mydata, setMydata] = useState();
    const [mydata1, setMydata1] = useState();
    const [todayareasum, setTodayareasum] = useState();
    const [todaypang, setTodaypang] = useState();
    const [areasum, setAreasum] = useState();
    const [pang, setPang] = useState();
    // console.log(typeof(cookies.get('user')[0]["รหัสพนักงาน"]))
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    const formattedToday = dd + '-' + mm + '-' + yyyy;

    const todatarr = []
    function getData() {
        axios.get('https://api.kasetr.com/getMainData.php').then(res => {
            var data = res.data
            console.log(data)
            setMydata(data);
            let todayarea = 0;
            let todaycon = 0;
            let area = 0;
            let con = 0;

            for (var ii in data) {


              
                if (formattedToday == data[ii]["tm1"]){
                
                    console.log(data[ii]["tm1"])
                    console.log(data[ii])
                    todatarr.push(data[ii])
                    // setMydata1(data[ii])
                    todayarea = todayarea + parseFloat(data[ii]["พื้นที่แจ้งจด"])
                    todaycon = todaycon + 1
                 
                }

            }
            setTodaypang(todaycon);
            setTodayareasum(todayarea.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
            
            setMydata1(todatarr)


            for (var i in data) {


                area = area + parseFloat(data[i]["พื้นที่แจ้งจด"])
                con = con + 1

            }

            setAreasum(area.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
            setPang(con)



        })
    }



    const cookies = new Cookies();
    if (undefined == cookies.get("user")) {
    window.location.href = "/login";


} else {

  if ( "นักส่งเสริม" == cookies.get('user')[0]["ตำแหน่ง"]) {
  
    window.location.href = "/login";
  }else{



    return (



        <div className='container-fluid'>
            <NavBar />
            <body>

                <div className='top1  '>
                    <div className="">
                        <div className="row g-4 ">
                            <div className="col-sm-6 col-xl-3">
                                <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                                    <i className="fa fa-chart-line fa-3x text-primary"><h1><MdToday /></h1></i>
                                    <div className="ms-3">
                                        <p className="mb-2"><h5>จดแจ้งประจำวัน</h5></p>
                                        <p className="mb-2">จำนวน  {todaypang} แปลง</p>
                                        <h6 className="mb-0">พื้นที่ {todayareasum} ไร่</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-3">
                                <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                                    <i className="fa fa-chart-bar fa-3x text-primary"><h1><PiMapPinSimpleAreaBold /></h1></i>
                                    <div className="ms-3">
                                        <p className="mb-2"><h5>จดแจ้งยอดสะสม</h5></p>
                                        <p className="mb-2">จำนวน  {pang} แปลง</p>
                                        <h6 className="mb-0">พื้นที่  {areasum}  ไร่</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-3">
                                <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                                    <i className="fa fa-chart-area fa-3x text-primary"><h1><GiFarmTractor /></h1></i>
                                    <div className="ms-3">
                                        <p className="mb-2"><h5>กำลังเตรียมดิน</h5></p>
                                        <p className="mb-2">จำนวน  0 แปลง</p>
                                        <h6 className="mb-0">พื้นที่ 0 ไร่</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-3">
                                <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                                    <i className="fa fa-chart-pie fa-3x text-primary"><h1><GiSugarCane /></h1></i>
                                    <div className="ms-3">
                                        <p className="mb-2"><h5>พื้นที่ปลูกจบแล้ว</h5></p>
                                        <p className="mb-2">จำนวน  0 แปลง</p>
                                        <h6 className="mb-0">พื้นที่ 0 ไร่</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="table-responsive">
                <table className="table table-hover  w-auto mw-100">
                    <thead>
                        <tr>
                            <th scope="col">id</th>
                            <th scope="col">วันที่แจ้ง</th>
                            <th scope="col">เขต</th>
                            <th scope="col">โควต้า</th>
                            <th scope="col">ชื่อชาวไร่</th>
                            <th scope="col">พื้นที่จดแจ้ง</th>
                            <th scope="col">ชื่อนสร.</th>
                            <th scope="col">วันพร้อมปลูก</th>
                        </tr>
                    </thead>
                    <tbody>


                        {mydata1?.map((d, i) => (

                            <tr>
                                < td >{i + 1}</td>
                                < td >{d["tm1"]}</td>

                                < td >{d["เขต"]}</td>
                                < td >{d["รหัสโควต้า"]}</td>
                                < td >{d["ชื่อชาวไร่"]}</td>
                                < td >{parseFloat(d["พื้นที่แจ้งจด"]).toFixed(2)}</td>
                                < td >{d["ชื่อนสร"]}</td>
                                < td >{d["วันพร้อมปลูก"]}</td>

                            </tr>

                        ))}






                    </tbody>
                </table>
                </div>  


            </body>
        </div>

    )

  }
}




}

export default DashBoard