import React, { useState } from 'react'

import './HomePage.css'
import NavBar from '../NavBar/NavBar';
import Cookies from 'universal-cookie';
import axios from 'axios'


function HomePage() {

    const [ip, setIP] = useState("");

    const getIpTime = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        console.log(res.data);
        alert(res.data.ip)
        setIP(res.data.ip);
        // console.log(Date.now())
    };


    const cookies = new Cookies();




    //   console.log(typeof(cookies.get('user')[0]["รหัสพนักงาน"]))

    function testcookie() {
        if (undefined == cookies.get("user")) {
            window.location.href = "/login";


        } else {
           console.log("ตอนนี้มีคุกกี้")
        }
    };



    return (

        <div className='container-fluid'>
            <NavBar />
            <body>
                <button type="button" class="w-100 btn btn-primary" onClick={getIpTime}>Get Ip</button><p></p>
                <button type="button" class="w-100 btn btn-primary" onClick={testcookie}>ทดสอบคุกกี้</button><p></p>

                <div className='allitems'>
                    <div className='item1'>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                        <h3>item1</h3>
                    </div>

                    <div className='item2'>
                        <h3>item2</h3>

                    </div>

                    <div className='item3'>
                        <h3>item3</h3>


                    </div>



                </div>


            </body>



        </div>







    );
}

export default HomePage